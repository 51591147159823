import React from 'react';
import { Helmet } from 'react-helmet';
import Header from './Header';
import GlobalStyles from '../styles/GlobalStyles';

const Layout = ({ children }) => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
      <title>CancerGxSuite</title>
    </Helmet>
    <GlobalStyles />
    <Header />
    <main>{children}</main>
  </>
);

export default Layout;
