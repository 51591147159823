import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import isotype from '../images/isotype.png';
import colors from '../styles/colors';

const StyledHeader = styled.div`
  position: absolute;
  width: 100%;
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > * {
    margin: 0 40px;
  }
  img {
    width: 50px;
    transition: all 0.2s ease-out 0s;
  }

  .links {
    a {
      margin: 0 20px;
      font-size: 1.2em;
      color: ${colors.main_text};
      border-bottom: 2px solid transparent;
      &:hover {
        border-bottom: 2px solid ${colors.main_text};
      }
    }
  }

  .active {
    border-bottom: 2px solid ${colors.main_text} !important;
  }
  @media only screen and (max-width: 800px) {
    img {
      width: 40px;
    }
  }
  @media only screen and (max-width: 1120px) {
    background: #f3f4f9;
    border-bottom: 1px solid #b0b0b0;
  }
`;
const Header = () => (
  <StyledHeader>
    <Link to="/"><img src={isotype} alt="cancergx logo" /></Link>
    <div className="links">
      <Link activeClassName="active" to="/about">About</Link>
      <a href="https://bhklab.ca">BHKLab</a>
    </div>
  </StyledHeader>
);

export default Header;
